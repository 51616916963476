@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 80vh; */
  flex-grow: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

:root {
  --burnt-orange: #bf5700;
}

body {
  font-family: 'Josefin Slab', serif !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  font-size: 24px;
}

.loading-screen-fade-out {
  opacity: 0;
}

.full-opacity {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.zero-opacity {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.blue-icon {
  color: #1d8bcf !important;
}

.burnt-orange-hover:hover {
  color: var(--burnt-orange) !important;
  transition: color 0.3s ease-in-out;
}

.foo2 {
  color: #CC0000FF,
  #CC1E00FF,
  #CC3E00FF,
  #CC5C00FF,
  #CC7A00FF,
  #CC9900FF,
  #CCB700FF,
  #C2CC00FF,
  #A3CC00FF,
  #85CC00FF,
  #66CC00FF,
  #47CC00FF,
  #29CC00FF,
  #0ACC00FF,
  #00CC14FF,
  #00CC33FF,
  #00CC52FF,
  #00CC70FF,
  #00CC8EFF,
  #00CCAEFF,
  #00CCCCFF,
  #00AECCFF,
  #008FCCFF,
  #0070CCFF,
  #0052CCFF,
  #0033CCFF,
  #0015CCFF,
  #0A00CCFF,
  #2900CCFF,
  #4700CCFF,
  #6600CCFF,
  #8500CCFF,
  #A300CCFF,
  #C200CCFF,
  #CC00B8FF,
  #CC0099FF,
  #CC007AFF,
  #CC005CFF,
  #CC003EFF,
  #CC001EFF,
}

.foo {
  color: #FF0000,
  #FF2600,
  #FF4D00,
  #FF7300,
  #FF9900,
  #FFBF00,
  #FFE500,
  #F2FF00,
  #CCFF00,
  #A6FF00,
  #80FF00,
  #59FF00,
  #33FF00,
  #0DFF00,
  #00FF19,
  #00FF40,
  #00FF66,
  #00FF8C,
  #00FFB2,
  #00FFD9,
  #00FFFF,
  #00D9FF,
  #00B3FF,
  #008CFF,
  #0066FF,
  #0040FF,
  #001AFF,
  #0D00FF,
  #3300FF,
  #5900FF,
  #7F00FF,
  #A600FF,
  #CC00FF,
  #F200FF,
  #FF00E6,
  #FF00BF,
  #FF0099,
  #FF0073,
  #FF004D,
  #FF0026,
}

.toggle-label-disabled {
  color: var(--bs-tertiary-color);
}

.emph-on-hover {
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.2s;
  color: var(--bs-card-color)
}

.emph-on-hover:hover {
  text-shadow: 
    -0.2px -0.2px 0 currentColor,
    0.2px -0.2px 0 currentColor,
    -0.2px 0.2px 0 currentColor,
    0.2px 0.2px 0 currentColor;
  transition: all 0.2s;
}

.burnt-orange {
  color: var(--burnt-orange) !important;
}

.footer {
  width: 100%;
  background-color: var(--bs-tertiary-bg);
}

/* .searchbar-full {
  min-width: 100%;
} */

.searchbar-cover {
  min-width: 700px;
}

.tablecard-table {
  height: 55vh;
}

/* Large or less */ 
@media only screen and (max-width: 992px) {
  .searchbar-cover {
    min-width: 60vw;
  }
}

/* Medium or less */
@media only screen and (max-width: 768px) {
  .searchbar-cover {
    min-width: 60vw;
  }
  
  .tablecard-table {
    height: 30vh;
  }
}

/* Small or less */
@media only screen and (max-width: 576px) {
  .searchbar-cover {
    min-width: 67vw;
  }
}

.graphcard-empty {
  min-height: 60vh;
}

.savecard-cell {
  border-width: var(--bs-border-width);
  border-color: var(--bs-border-color);
  border-style: solid;
}

.savecard-cell:hover {
  background-color: rgba(var(--bs-emphasis-color-rgb), 0.075);
}

/* .savecard-cell-regular {} */

.savecard-cell-striped {
  background-color: rgba(var(--bs-emphasis-color-rgb), 0.05);
}

.tooltip {
  font-size: 3rem;
}

/* ul {
  margin: 0;
} */
ul.dashed {
  list-style-type: none;
  margin: 0;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}
